<template>
  <div class="mention-tracking-group-report">
    <!-- In case we're showing a preview -->
    <div
      v-if="!isAuthenticated"
      class="mb-8"
    >
      <!-- Show the report logo, if any -->
      <v-img
        v-if="overview.model.logo"
        :src="reportLogo"
        height="100"
        contain
      />

      <!-- Show the report name -->
      <div class="text-h6 font-weight-bold text-center primary--text mt-3">
        {{ overview.model.name }}
      </div>

      <!-- Show the date range -->
      <div class="text-center text-subtitle-2 mt-1">
        {{ dayjs(overview.model.start_date).format("ll") }} - {{ dayjs(overview.model.end_date).format("ll") }}
      </div>
    </div>

    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-8">
      <!-- Show the go back button -->
      <v-btn
        v-if="isAuthenticated"
        text
        color="primary"
        @click="$router.push({ name: 'MentionTrackingGroupIndex' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Back
      </v-btn>
      <!-- Don't show anything in the left -->
      <span v-else></span>

      <div>
        <!-- Show the menu here -->
        <v-menu
          v-if="isAuthenticated"
          transition="slide-y-transition"
          offset-y
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <!-- Show the menu options -->
          <v-list width="160" dense>
            <!-- Show the option to trigger share -->
            <v-list-item
              v-if="overview.canUserWrite"
              @click="shouldShowShareDialog = true"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Share
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as PDF -->
            <v-list-item
              @click="triggerDownload('pdf')"
              :disabled="isMakingDownloadRequest.pdf"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Download PDF
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as XLSX -->
            <v-list-item
              @click="triggerDownload('xlsx')"
              :disabled="isMakingDownloadRequest.xlsx"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Download XLSX
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="d-flex justify-space-between mb-4">
      <!-- Show the report name -->
      <div
        v-if="isAuthenticated"
        class="text-h6 font-weight-bold mt-3"
      >
        {{ overview.model.name }}
      </div>
    </div>

    <!-- Show the report brief here -->
    <div class="d-flex flex-wrap justify-center justify-md-space-between">
      <div class="d-flex align-center px-4 px-md-0">
        <!-- For each platform in this model, show their logo image -->
        <div class="d-flex">
          <v-img
            v-for="(platform, index) in overview.model.platforms"
            :key="'platform-logo-' + index"
            :src="`/img/socials/${platform}.svg`"
            width="32"
            height="32"
            max-width="32"
            class="mr-3"
          />
        </div>

        <!-- Show the date range value -->
        <div class="text-subtitle-2 font-weight-bold my-4">
          {{ dayjs(overview.model.start_date).format("ll") }} - {{ dayjs(overview.model.end_date).format("ll") }}
        </div>
      </div>
    </div>

    <!-- Show a divider -->
    <v-divider class="my-8" />

    <!-- Show a table for counts -->
    <v-data-table
      :headers="overviewTableHeaders"
      :items="overviewTableItems"
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="100"
    >
      <!-- Slot template for header -->
      <template
        v-for="(report, index) in overview.reports"
        v-slot:[`header.report-${report.id}`]="{ header }"
      >
        <div class="d-flex align-center">
          <!-- Show the text -->
          <span>
            {{ header.text }}
          </span>

          <!-- Show the color dot -->
          <span
            class="ml-2"
            style="width: 12px; height: 12px; border-radius: 100%; display: inline-block;"
            :style="{ backgroundColor: colors[index] }"
          ></span>
        </div>
      </template>

      <!-- Slot templates for values -->
      <template
        v-for="report in overview.reports"
        v-slot:[`item.report-${report.id}`]="{ item }"
      >
        <!-- Show numeric value -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ nFormatter(item[`report-${report.id}`]) }}
            </span>
          </template>

          <span>
            Total {{ item.key }}: {{ item[`report-${report.id}`] }}
          </span>
        </v-tooltip>

        <!-- Show percentage value -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="ml-md-2 text-caption"
            >
              ({{ item[`report-${report.id}-percentage`] }})
            </span>
          </template>

          <span>
            Percentage Share: {{ item[`report-${report.id}-percentage`] }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- Show tabs for graphs -->
    <v-tabs
      v-model="selectedPostsVsTab"
      background-color="transparent"
      class="mt-8"
    >
      <v-tab>
        Posts vs Influencers
      </v-tab>

      <v-tab>
        Posts vs Engagements
      </v-tab>
    </v-tabs>

    <!-- Show a grid -->
    <v-row
      class="mt-8"
    >
      <!-- Show posts share -->
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column align-center"
      >
        <div class="text-overline" style="margin-bottom: -30px;">
          Posts Share
        </div>

        <distribution-chart
          :height="240"
          :colors="colors"
          :labels="overview.reports.map(report => report.name)"
          :data="overview.reports.map(report => report.categories_data[0].posts)"
        />
      </v-col>

      <!-- Show influencers/engagements share -->
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column align-center"
      >
        <div class="text-overline" style="margin-bottom: -30px;">
          {{ selectedPostsVsTab === 0 ? "Influencers" : "Engagements" }} Share
        </div>

        <distribution-chart
          :height="240"
          :colors="colors"
          :labels="overview.reports.map(report => report.name)"
          :data="overview.reports.map(report => selectedPostsVsTab === 0 ? report.categories_data[0].accounts : (report.categories_data[0].likes + report.categories_data[0].comments + report.categories_data[0].saves + report.categories_data[0].shares))"
        />
      </v-col>
    </v-row>

    <!-- Show a divider -->
    <v-divider class="mt-8" />

    <div class="text-overline mt-2 mb-4">
      Publishing Timeline
    </div>

    <!-- Show the tabs for graph -->
    <v-tabs
      v-model="selectedDaywiseTab"
      background-color="transparent"
    >
      <v-tab>
        Influencers
      </v-tab>

      <v-tab>
        Posts
      </v-tab>
    </v-tabs>

    <!-- Show the data-wise graph -->
    <day-wise-graph
      v-if="isComputedDataReady"
      :series="selectedDaywiseTab === 1 ? dayWisePostsData : dayWiseInfluencersData"
      :axis-title="selectedDaywiseTab === 1 ? 'Posts' : 'Influencers'"
      :colors="colors"
    />

    <!-- Show a divider -->
    <v-divider class="mt-8" />

    <div class="text-overline mt-2 mb-4">
      Categories Breakdown
    </div>

    <!-- Show the tabs for table -->
    <v-tabs
      v-model="selectedCategoriesTab"
      background-color="transparent"
      show-arrows
    >
      <v-tab
        v-for="tab in categoryTabs"
        :key="tab.value"
        :tab-value="tab.value"
      >
        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <!-- Show teh categories breakdown chart -->
    <div class="my-4">
      <categories-breakdown-chart
        :yaxis="categoriesChartYAxis"
        :xaxis="categoriesChartXAxis"
        :series="categoriesChartSeries"
        :colors="colors"
        :is-y-axis-percentage="selectedCategoriesTab === 'engagement_rate'"
      />
    </div>

    <!-- Show the table -->
    <v-data-table
      :headers="categoryTableHeaders"
      :items="categoryTableItems"
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="100"
    >
      <template v-slot:item.report="{ item }">
        <span class="font-weight-bold">
          {{ item.report }}
        </span>
      </template>
    </v-data-table>

    <!-- Show a divider -->
    <v-divider class="mt-8" />

    <div class="text-overline mt-2 mb-4">
      Posts Type Breakdown
    </div>

    <!-- Show teh posts-type breakdown chart -->
    <div class="my-4">
      <posts-type-chart
        :series="postsTypeSeries"
        :colors="colors"
      />
    </div>

    <!-- Show the table -->
    <v-data-table
      :headers="postsTypeTableHeaders"
      :items="postsTypeTableItems"
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="100"
    >
      <template v-slot:item.report="{ item }">
        <span class="font-weight-bold">
          {{ item.report }}
        </span>
      </template>
    </v-data-table>

    <!-- Show a divider -->
    <v-divider class="mt-8" />

    <div class="mt-3">      <!-- Show tabs -->
      <v-tabs
        v-model="selectedReportIdForPosts"
        background-color="transparent"
        show-arrows
      >
        <v-tab
          v-for="report in overview.reports"
          :key="report.id"
          :tab-value="report.id"
        >
          {{ report.name }}
        </v-tab>
      </v-tabs>

      <!-- Show the sort and search options -->
      <div class="d-flex justify-space-between mt-8">
        <!-- Show the sort option -->
        <div class="contain-select-width">
          <v-select
            v-model="sortBy"
            :items="sortOptions"
            label="Sort By"
            color="primary"
            hide-details
            outlined
            dense
          ></v-select>
        </div>

        <div class="contain-select-width contain-select-width--2x">
          <v-select
            v-model="followersFilterBy"
            :items="followersFilterOptions"
            label="Followers' Range"
            color="primary"
            hide-details
            outlined
            dense
          ></v-select>
        </div>
      </div>

      <!-- Show the posts grid -->
      <v-row
        v-if="response.posts.data.length"
        class="mt-6"
      >
        <v-col
          v-for="item in response.posts.data"
          :key="'post-' + item.id"
          class="px-2"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <!-- Use a common posts component -->
          <post :data="item" />
        </v-col>
      </v-row>

      <div
        v-else
        class="text-center mt-8 font-weight-bold"
      >
        {{ isRequestingSearch.posts ? 'Loading ...' : 'No posts found, please try changing the filters.' }}
      </div>

      <div
        id="intersect-detector"
        ref="intersectDetector"
        v-intersect="handleIntersect"
      ></div>

    </div>

    <!-- Show a dialog for managing sharing access -->
    <share-dialog
      v-model="shouldShowShareDialog"
      module="mentionTrackingGroup"
      :overview="overview"
    />
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const Post = () => import(/* webpackChunkName: "crm-post" */ "@/components/crm/Post.vue")
const ShareDialog = () => import(/* webpackChunkName: "share-dialog" */ "@/components/crm/ShareDialog")
const DayWiseGraph = () => import(/* webpackChunkName: "crm-day-wise" */ "@/components/crm/DayWise")
const PostsTypeChart = () => import(/* webpackChunkName: "posts-type-chart" */ "@/components/crm/mention-tracking-group/PostsTypeChart")
const DistributionChart = () => import(/* webpackChunkName: "distribution-chart" */ "@/components/common/DistributionChart")
const CategoriesBreakdownChart = () => import(/* webpackChunkName: "categories-breakdown-chart" */ "@/components/crm/mention-tracking-group/CategoriesBreakdownChart")

// Import helper objects
import followersFilterOptions from "@/helpers/paid-collaborations/filterOptions.js"

// Subscription ID for messageEvents
const subscriptionId = Symbol("MentionTrackingGroup/Report")

// Export the SFC
export default {
  // Name of the component
  name: "MentionTrackingGroupReport",

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    }
  },

  // Register child components
  components: {
    Post,

    ShareDialog,
    DayWiseGraph,
    PostsTypeChart,
    DistributionChart,
    CategoriesBreakdownChart
  },

  // Define local data variables
  data: () => ({
    // Followers filter values
    followersFilterBy: 0,
    followersFilterOptions: followersFilterOptions(),

    // Sort filter values
    sortBy: "most_liked",
    sortOptions: [
      {
        text: "Recent",
        value: "recent"
      },
      {
        text: "Oldest",
        value: "oldest"
      },
      {
        text: "Least Liked",
        value: "least_liked"
      },
      {
        text: "Most Liked",
        value: "most_liked"
      },
      {
        text: "Least Commented",
        value: "least_commented"
      },
      {
        text: "Most Commented",
        value: "most_commented"
      },
      {
        text: "Lowest Credibility",
        value: "lowest_credibility"
      },
      {
        text: "Highest Credibility",
        value: "highest_credibility"
      },
      {
        text: "Lowest Followers",
        value: "lowest_followers"
      },
      {
        text: "Highest Followers",
        value: "highest_followers"
      }
    ],
    sortMap: {
      recent: {
        sortBy: "posted_at",
        sortOrder: "desc"
      },

      oldest: {
        sortBy: "posted_at",
        sortOrder: "asc"
      },

      least_liked: {
        sortBy: "likes",
        sortOrder: "asc"
      },

      most_liked: {
        sortBy: "likes",
        sortOrder: "desc"
      },

      least_commented: {
        sortBy: "comments",
        sortOrder: "asc"
      },

      most_commented: {
        sortBy: "comments",
        sortOrder: "desc"
      },

      lowest_credibility: {
        sortBy: "audience_credibility",
        sortOrder: "asc"
      },

      highest_credibility: {
        sortBy: "audience_credibility",
        sortOrder: "desc"
      },

      lowest_followers: {
        sortBy: "followers",
        sortOrder: "asc"
      },

      highest_followers: {
        sortBy: "followers",
        sortOrder: "desc"
      }
    },

    isRequestingSearch: {
      posts: false,
      influencers: false
    },

    pagination: {
      posts: {
        page: 1,
        perPage: 8
      },
      influencers: {
        page: 1,
        perPage: 10
      }
    },
    response: {
      posts: {
        data: [],
        total: 0
      },
      influencers: {
        data: [],
        total: 0
      }
    },

    // Whether or not is making a download
    isMakingDownloadRequest: {
      pdf: false,
      xlsx: true
    },
    triggerId: {
      pdf: null,
      xlsx: null
    },
    downloadLoaders: {
      pdf: null,
      xlsx: null,
    },

    // Whether or not to show the shareable options dialog
    shouldShowShareDialog: false,

    // Whether to show posts vs influencers or engagements graph
    selectedPostsVsTab: 0,

    // Store the computed posts data
    dayWisePostsData: [],
    dayWiseInfluencersData: [],
    selectedDaywiseTab: 0,

    // Whether or not is the computed data ready
    isComputedDataReady: false,

    // The colors to be used
    colors: [
      "#f05d56",
      "#1890FF",
      '#e377c2',
      '#ff7f0e',
      '#2ca02c',
    ],

    // The selected categories tab
    selectedCategoriesTab: "accounts",
    categoryTabs: [
      {
        text: "Influencers",
        value: "accounts",
      },
      {
        text: "Posts",
        value: "posts",
      },
      {
        text: "Followers",
        value: "followers",
      },
      {
        text: "Likes",
        value: "likes",
      },
      {
        text: "Comments",
        value: "comments",
      },
      {
        text: "Views",
        value: "views",
      },
      {
        text: "Shares",
        value: "shares",
      },
      {
        text: "Engagement Rate",
        value: "engagement_rate",
      }
    ],

    categoryTableHeaders: [
      {
        text: "Report",
        value: "report"
      },
      {
        text: "All",
        value: "all"
      },
      {
        text: "Nano (< 10k)",
        value: "nano"
      },
      {
        text: "Micro (10k - 100k)",
        value: "micro"
      },
      {
        text: "Macro (100k - 1M)",
        value: "macro"
      },
      {
        text: "Mega (> 1M)",
        value: "mega"
      }
    ],

    postsTypeTableHeaders: [
      {
        text: "Report",
        value: "report"
      },
      {
        text: "Photos",
        value: "photos"
      },
      {
        text: "Videos",
        value: "videos"
      },
      {
        text: "Carousels",
        value: "carousels"
      }
    ],

    selectedReportIdForPosts: null
  }),

  // Define local readonly variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the report logo
     *
     * @returns {String}
     */
    reportLogo() {
      return this.overview.model.logo ? `${process.env.VUE_APP_STORAGE_BASE}/${this.overview.model.logo}` : null
    },

    /**
     * Get the overview table headers
     *
     * @returns {Array}
     */
    overviewTableHeaders() {
      return [
        {
          text: "",
          value: "key",
          sortable: false,
        },
        ...this.overview.reports.map((report) => ({
          text: report.name,
          value: "report-" + report.id,
          sortable: false
        }))
      ]
    },

    /**
     * Get the overview table items
     *
     * @returns {Array}
     */
    overviewTableItems() {
      // Get sum of all posts and influencers
      const totalPosts = this.overview.reports.reduce((acc, report) => acc + report.categories_data[0].posts, 0)
      const totalInfluencers = this.overview.reports.reduce((acc, report) => acc + report.categories_data[0].accounts, 0)

      // Initiate variables
      const posts = { key: "Posts" }
      const influencers = { key: "Influencers" }

      // Loop through the reports
      for (const report of this.overview.reports) {
        // Calculate the percentage of total
        const postsPercentage = (report.categories_data[0].posts / totalPosts) * 100
        const influencersPercentage = (report.categories_data[0].accounts / totalInfluencers) * 100

        // Push the data
        posts["report-" + report.id] = report.categories_data[0].posts
        posts["report-" + report.id + "-percentage"] = numeral.fPercent(postsPercentage)

        influencers["report-" + report.id] = report.categories_data[0].accounts
        influencers["report-" + report.id + "-percentage"] = numeral.fPercent(influencersPercentage)
      }

      return [
        influencers,
        posts
      ]
    },

    /**
     * Get the computed table items for the selected tab
     *
     * @returns {Array}
     */
    categoryTableItems() {
      return this.overview.reports.map((report) => {
        // Initiate the object
        const object = {
          report: report.name
        }

        // List of category keys
        const keys = [
          "all",
          "nano",
          "micro",
          "macro",
          "mega"
        ]

        // Keep an index
        let index = 0

        // Loop through categories
        for (const category of report.categories_data) {
          // Push the values
          object[keys[index]] = this.selectedCategoriesTab === "engagement_rate" ? numeral.fPercent(category[this.selectedCategoriesTab] * 100) : nFormatter(category[this.selectedCategoriesTab])

          // Increment the index
          index++
        }

        // Return the object
        return object
      })
    },

    /**
     * Get the categories chart X axis value
     *
     * @returns {Array}
     */
    categoriesChartXAxis() {
      return[ ...this.categoryTableHeaders.slice(1).map(item => item.text) ]
    },

    /**
     * Get the categories chart Y axis value
     *
     * @returns {String}
     */
    categoriesChartYAxis() {
      return this.categoryTabs.find(item => item.value === this.selectedCategoriesTab).text
    },

    /**
     * Get the categories chart series value
     *
     * @returns {Array}
     */
    categoriesChartSeries() {
      return this.overview.reports.map((report) => {
        return {
          name: report.name,
          data: report.categories_data.map(item => item[this.selectedCategoriesTab])
        }
      })
    },

    /**
     * Get the computed table items for the posts type
     *
     * @returns {Array}
     */
    postsTypeTableItems() {
      return this.overview.reports.map((report) => ({
        report: report.name,
        photos: nFormatter(report.categories_data[0].photo_posts),
        videos: nFormatter(report.categories_data[0].video_posts),
        carousels: nFormatter(report.categories_data[0].carousel_posts)
      }))
    },

    /**
     * Get the posts type chart series value
     *
     * @returns {Array}
     */
    postsTypeSeries() {
      return this.overview.reports.map((report) => {
        return {
          name: report.name,
          data: [
            report.categories_data[0].photo_posts,
            report.categories_data[0].video_posts,
            report.categories_data[0].carousel_posts
          ]
        }
      })
    }
  },

  // Watch for any changes
  watch: {
    // If the sort filter changes
    sortBy() {
      // Reset the pagination
      this.response.posts.data = []
      this.pagination.posts.page = 1
      this.pagination.influencers.page = 1

      // Call the search function
      this.searchPosts()
      this.searchInfluencers()
    },

    // If the followers filter changes
    followersFilterBy() {
      // Reset the pagination
      this.response.posts.data = []
      this.pagination.posts.page = 1
      this.pagination.influencers.page = 1

      // Call the search function
      this.searchPosts()
      this.searchInfluencers()
    },

    // If the selected report changes
    selectedReportIdForPosts() {
      // Reset the pagination
      this.response.posts.data = []
      this.pagination.posts.page = 1

      // Call the search function
      this.searchPosts()
    }
  },

  // Define local method functions
  methods: {
    /**
     * Whether or not the user has scrolled past the intersection limit
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isRequestingSearch.posts) return false

      // If there's no data possible, don't continue
      if (this.response.posts.data.length >= this.response.posts.total) return false

      // Otherwise, call the search function
      this.pagination.posts.page++
      this.searchPosts()
    },

    /**
     * Fetch posts to be shown in grid
     *
     * @returns {void}
     */
    async searchPosts() {
      // Show a loader
      const loaderId = Symbol("MentionTrackingGroup/Report/searchPosts")
      this.$store.dispatch("loaders/add", loaderId)
      this.isRequestingSearch.posts = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.pagination.posts.page,
            per_page: this.pagination.posts.perPage,
            minimumFollowers: this.followersFilterOptions[this.followersFilterBy].min,
            maximumFollowers: this.followersFilterOptions[this.followersFilterBy].max === Infinity ? null : this.followersFilterOptions[this.followersFilterBy].max,
            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/mention-tracking-groups/${this.overview.model.id}/posts/${this.selectedReportIdForPosts}?${queryParams}`)

        // Update the response object
        this.response.posts = {
          total: response.data.total,
          data: [
            ...this.response.posts.data,
            ...response.data.data
          ]
        }
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch posts!" })

        // Log it
        logger({ type: "MentionTrackingGroup/Report/searchPosts", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isRequestingSearch.posts = false
      }
    },

    /**
     * Fetch influencers to be shown in table
     *
     * @returns {void}
     */
    async searchInfluencers() {
      return
      // Show a loader
      const loaderId = Symbol("MentionTrackingGroup/Report/searchInfluencers")
      this.$store.dispatch("loaders/add", loaderId)
      this.isRequestingSearch.influencers = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.pagination.influencers.page,
            per_page: this.pagination.influencers.perPage,
            minimumFollowers: this.followersFilterOptions[this.followersFilterBy].min,
            maximumFollowers: this.followersFilterOptions[this.followersFilterBy].max === Infinity ? null : this.followersFilterOptions[this.followersFilterBy].max,
            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/mention-tracking-groups/${this.overview.model.id}/influencers?${queryParams}`)

        // Update the response object
        this.response.influencers = {
          total: response.data.total,
          data: response.data.data
        }
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch influencers!" })

        // Log it
        logger({ type: "MentionTrackingGroup/Report/searchInfluencers", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isRequestingSearch.influencers = false
      }
    },

    /**
     * Handle the request to export this report as PDF
     *
     * @param {String} type
     * @returns {void}
     */
    async triggerDownload(type) {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDownloadRequest[type] = true

      // Make a network request
      try {
        // Set a triggerId
        this.triggerId[type] = String(Date.now())

        // Use helper function
        await axios({
          url: `/api/mention-tracking-groups/${this.overview.model.id}/download/${type}`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId[type]
          }
        })
      }
      // Catch the error
      catch (error) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try later." })

        // Hide the loader
        this.isMakingDownloadRequest[type] = false
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please wait while we generate your report..." })
      }
    },

    /**
     * Stop the download progress
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-mention-tracking-group-pdf-started":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.pdf = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.pdf)

          break

        case "generate-mention-tracking-group-pdf-completed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-mention-tracking-group-pdf-failed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-mention-tracking-group-xlsx-started":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.xlsx = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.xlsx)

          break

        case "generate-mention-tracking-group-xlsx-completed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break

        case "generate-mention-tracking-group-xlsx-failed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break
      }
    },

    /**
     * Compute the data for the day-wise graph
     *
     * @returns {void}
     */
    computeData() {
      // Reset the values
      this.dayWisePostsData = []
      this.dayWiseInfluencersData = []

      // Loop through the reports
      for (const report of this.overview.reports) {
        // Get the minimum date for this campaign
        const startDate = dayjs(this.overview.model.start_date)
        // Get the maximum date for this campaign
        const endDate = dayjs(this.overview.model.end_date)

        // Get the minimum post date value
        const minPostDate = dayjs(report.daywise_data.posts[0].date)
        // Get the maximum post date value
        const maxPostDate = dayjs(report.daywise_data.posts[report.daywise_data.posts.length - 1].date)

        // Get the minimum date value by comparing the start date and the min post date
        const minDate = startDate.isBefore(minPostDate) ? startDate : minPostDate
        // Get the maximum date value by comparing the end date and the max post date
        const maxDate = endDate.isAfter(maxPostDate) ? endDate : maxPostDate

        // The daywise posts and influencers count array
        const dayWisePosts = {
          name: report.name,
          data: []
        }

        const dayWiseInfluencers = {
          name: report.name,
          data: []
        }

        // Go through each day between the min and max date, and add it to the dates array
        for (let date = minDate; date <= maxDate; date = dayjs(date).add(1, "day")) {
          // Get the posts value for this date
          const post = report.daywise_data.posts.find((item) => dayjs(item.date).isSame(date, "day"))

          // Push it to the list
          dayWisePosts.data.push([
            date.unix() * 1000,
            post ? post.count : 0
          ])

          // Get the influencers value for this date
          const influencer = report.daywise_data.influencers.find((item) => dayjs(item.date).isSame(date, "day"))

          // Push it to the list
          dayWiseInfluencers.data.push([
            date.unix() * 1000,
            influencer ? influencer.count : 0,
          ])
        }

        // Append the values
        this.dayWisePostsData.push(dayWisePosts)
        this.dayWiseInfluencersData.push(dayWiseInfluencers)
      }

      // Set the computed data ready flag
      this.isComputedDataReady = true
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Compute the graph data
    this.computeData()

    // Set the selected report id
    this.selectedReportIdForPosts = this.overview.reports[0].id

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "mention-tracking-group",
      type: "all",
      key: "all",
      validator: (event) => event.module === "mention-tracking-group" && event.localData.modelId === this.overview.model.id,
      callback: this.handleMessageEvent
    })
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 15em

  &--large
    width 20em
</style>

